h1{
    line-height: 2em;
}

.cursorLink{
    cursor: pointer;
}

.fullwidth{
    width: 100%
}


.titlePage{
    border-bottom: 1px #CCCCCC solid;
    margin-bottom: 1em;
}

.saneiTitle{
    background-color: #333333;

}

.saneiTitle h2{
    font-size: 1em;
    line-height: 2.5em;
    color: #F1F1F1;
}

.blocInformationData{
    background-color: #F1F1F1;
    padding: 15px;
    text-align: center;
}

.sidebar {
    display: block;
    float: left;
    width: 230px;
}

.content {
    margin-left: 250px;
    overflow-x: hidden;
}

.content, .sidebar {
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
}

.sidebar .active-link{
    font-weight: bold;
}

.sidebar ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
.sidebar ul li{
    padding-left: 10px;
}

.sidebar ul ul{
    padding-left: 10px;
}

.blcTitlePage{
    border-bottom: solid 1px #F1F1F1;
    margin-bottom: 10px;
}

.blcTitlePage h1{
    font-size: 1.4em;
    line-height: 1.2em;
}

.blcTitlePage h2{
    font-size: 1.2em;
    line-height: 1.2em;
}

.text-left{
    text-align: left;
}

.text-right{
    text-align: right;
}


form{}

/**/

.table td.col30, .table th.col30{
    width: 30px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

.table td.col30 .form-check-input, .table th.col30 .form-check-input{
    position: relative;
    padding: 0;
    margin: 0;
}

/**/

.marginTB10{
    margin: 10px auto
}

/**/

.blocAction{
}

.blocAction .btn{
    margin: 0 5px;
}

.blocMenuHaut{
    float: left;
    margin: 10px auto;

}

.blocMenuHaut .subtitle{
    text-align: center;
    font-size: 0.75em;
}

/**/

.blocBoutonForm{}
.blocBoutonForm button{ 
    margin: 5px;
    padding: 10px;
}

.footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
    background-color: #FFFFFF;
    font-size: 10px;
}

 .error{
    background-color: red;
    color: #FFFFFF;
    font-size: 30px;
    position: fixed;
    width: 100%;
    height: 300px;
    top: 20%;
    z-index: 3;
    vertical-align: middle;
    padding-top: 75px;
}
/**/

.pagination .IsCurrent{
    font-weight: bold;
}

.pagination .page-link:hover{
    cursor: pointer;
}

.pagination .IsCurrent .page-link{
    background-color: #0d6efd;
    color: #F1F1F1;
}

@media screen and (min-width: 1200px) {
    .container{
        width: 100%;
        max-width: 100%;
    }
}